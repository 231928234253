import * as React from 'react'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'

import IndexLayout from '../layouts'
import { graphql } from 'gatsby'
import { Language } from 'src/state'
import { WithPosts } from 'src/components/Post'

const IndexCenteralizerContainer: React.SFC = ({ children }) => (
  <section
    style={{
      margin: '1em auto',
      width: 'calc(100vw - 48px)',
      minWidth: '290px',
      textAlign: 'left'
    }}
  >
    {children}
  </section>
)

const IndexSection: React.SFC<{ style?: React.CSSProperties }> = ({ children, style = {} }) => (
  <section style={{ margin: '2em 0', ...style }}>{children}</section>
)

const DateFromString: React.SFC<{
  label?: string
  datestring: string
  style?: React.CSSProperties
}> = ({ label = '', datestring, style }) => {
  const [date] = new Date(datestring).toISOString().split('T')
  const [year, month, day] = date.split('-')
  return (
    <span style={style}>
      {label} {day}/{month}/{year}
    </span>
  )
}

const IndexPage: React.SFC<{
  data: {
    site: { siteMetadata: { siteUrl: string } }
    prismicSettings: { data: { evaluation_enabled: boolean } }
  }
}> = ({ data }) => (
  <IndexLayout>
    <Language.Consumer>
      {({ translate }) => (
        <IndexCenteralizerContainer>
          <IndexSection>
            <h3>{translate('recent_updates')}</h3>
            <WithPosts
              render={posts =>
                posts.map((post, i) => (
                  <div key={i} className="post">
                    <h4>{post.node.data.title.text}</h4>
                    <div
                      style={{ marginBottom: '1em' }}
                      dangerouslySetInnerHTML={{ __html: post.node.data.content.html }}
                    />

                    {data.prismicSettings.data.evaluation_enabled && post.node.data.chave && (
                      <Button
                        className="post-action"
                        variant="outlined"
                        onClick={() =>
                          window.open(
                            `https://masterrhx.typeform.com/to/nE8Mw1?feature=${
                              post.node.data.chave
                            }`
                          )
                        }
                      >
                        {translate('feedback')}
                      </Button>
                    )}

                    {post.node.data.help_center_article && (
                      <Button
                        className="post-action"
                        variant="outlined"
                        onClick={() => window.open(post.node.data.help_center_article.url)}
                      >
                        {translate('see_more')}
                      </Button>
                    )}

                    <DateFromString
                      style={{ float: 'right', fontStyle: 'italic' }}
                      label={translate('published_at')}
                      datestring={post.node.first_publication_date}
                    />

                    <div style={{ clear: 'both' }} />
                  </div>
                ))
              }
            />
          </IndexSection>
        </IndexCenteralizerContainer>
      )}
    </Language.Consumer>
  </IndexLayout>
)

export const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicSettings {
      data {
        evaluation_enabled
      }
    }
  }
`

export default IndexPage
