import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Language } from 'src/state'

interface Post {
  node: {
    lang: string
    first_publication_date: string
    data: {
      chave: string
      help_center_article: {
        url: string
      }
      content: {
        html: string
      }
      title: {
        text: string
      }
    }
  }
}
type PostsRenderer = (data: Post[]) => React.ReactNode

export const WithPosts: React.SFC<{
  render: PostsRenderer
}> = ({ render }) => (
  <Language.Consumer>
    {({ selected = '' }) => (
      <StaticQuery
        render={data =>
          render(
            data.posts.edges.filter(
              (edge: Post) => edge.node.lang.toLowerCase().indexOf(selected.toLowerCase()) >= 0
            )
          )
        }
        query={graphql`
          {
            posts: allPrismicPost(sort: { fields: first_publication_date, order: DESC }) {
              edges {
                node {
                  lang
                  first_publication_date
                  data {
                    chave
                    help_center_article {
                      url
                    }
                    content {
                      html
                    }
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        `}
      />
    )}
  </Language.Consumer>
)
